<template>
  <form-component
    ref="forumDriver"
    class="forum-driver-form"
    @submit.prevent="handleFormSubmit"
  >
    <label
      class="forum-driver-form__question"
      :for="`answer_for_${engagementId}`"
    >
      {{ question }}
    </label>
    <div
      class="validation-group"
      :class="{ 'validation-group--invalid': $v.body.$invalid }"
    >
      <validation-error
        ref="body"
        :model="body"
        :validator="$v.body"
      />
      <vertical-expand-text-input
        :id="`answer_for_${engagementId}`"
        v-model="body"
        rows="1"
        placeholder="Type your response..."
        @focus="handleFocus"
      />
    </div>
    <p
      v-if="isFocused"
      class="forum-driver-form__disclaimer disclaimer-text"
    >
      Please
      <a
        href="https://health-union.com/community-rules/"
        target="_blank"
        rel="noopener noreferrer nofollow"
      >
        read our rules</a>
      before posting.
    </p>
    <loading-button
      type="submit"
      class="forum-driver-form__button"
      :loading="tryingToReply"
      :disabled="maxCharLimitExceeded"
      button-type="reply"
    >
      Share my response
    </loading-button>
  </form-component>
</template>
<script>
import LoadingButton from '@/components/Global/LoadingButton.vue';
import VerticalExpandTextInput from '@/components/Inputs/VerticalExpandTextInput.vue';
import { required } from 'vuelidate/lib/validators';
import ValidationError from '@/components/Global/ValidationError.vue';
import { CREATE_REPLY } from '@/graphql/mutations/thread-mutation';
import { REPLY_UPDATE_QUERY } from '@/graphql/queries/thread-reply-queries';
import { mapGetters } from 'vuex';

export default {
  name: 'ForumDriverForm',
  components: {
    LoadingButton,
    VerticalExpandTextInput,
    ValidationError,
  },
  props: {
    slugText: {
      type: String,
      default: '',
    },
    question: {
      type: String,
      required: true,
    },
    threadId: {
      type: Number,
      required: true,
    },
    engagementId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      tryingToReply: false,
      body: '',
      isFocused: false,
      submissionAttempted: false,
      maxLength: 250,
    };
  },
  validations: {
    body: {
      required,
    },
  },
  computed: {
    ...mapGetters([
      'userIsLoggedIn',
      'userIsUnverified',
    ]),
    maxCharLimitExceeded() {
      return this.body.length > this.maxLength;
    },
  },
  watch: {
    body(to, from) {
      if (to !== from && this.submissionAttempted) {
        this.$refs.body.checkErrors();
      }
    },
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
      if (!this.userIsLoggedIn) {
        this.$store.dispatch('openRegisterDialog', { dialogText: 'Create an account to respond.', isUGC: true });
      } else if (this.userIsLoggedIn && this.userIsUnverified) {
        this.$store.dispatch('openVerificationPrompt', { dialogHeading: 'Verify your account' });
      }
    },
    handleFormSubmit() {
      this.tryingToReply = true;
      this.$v.$touch();
      this.$refs.body.checkErrors();
      this.submissionAttempted = true;

      if (this.$v.$invalid) {
        this.tryingToReply = false;
        return;
      }
      // need to query the replies first in case they aren't yet in cached data to prevent
      // invariant violation error when updating the cache after calling the mutation
      this.$apollo.query({
        query: REPLY_UPDATE_QUERY,
        variables: {
          id: null,
          slug: this.slugText,
        },
      }).then(() => {
        this.$apollo.mutate({
          mutation: CREATE_REPLY,
          variables: {
            body: this.body,
            threadId: this.threadId,
            engagementId: this.engagementId,
          },
          update: (cache, { data: { createReply } }) => {
            const updatedUser = {
              ...createReply.user,
              badge: createReply.user?.badge ?? null,
            };

            const updatedReply = {
              ...createReply,
              user: updatedUser,
            };
            const data = cache.readQuery({
              query: REPLY_UPDATE_QUERY,
              variables: {
                id: null,
                slug: this.slugText,
              },
            });

            const sortType = this.$site.settings.comment_and_reply_sort;
            if (!sortType || sortType === 'desc') {
              data.thread.replies.unshift(updatedReply);
            } else {
              data.thread.replies.push(updatedReply);
            }

            cache.writeQuery({
              query: REPLY_UPDATE_QUERY,
              variables: {
                id: null,
                slug: this.slugText,
              },
              data,
            });
          },
        });
      }).then(() => {
        this.$emit('submitted');
      }).catch(() => {
        this.$store.dispatch('addGenericErrorNotification');
      })
        .finally(() => {
          this.tryingToReply = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_forum-driver-form';
</style>
