import debounce from 'lodash/debounce';
import { EngagementToolkitSvgFromContext } from '@/utils/image-helpers';

export default {
  data() {
    return {
      animationRunning: false,
    };
  },
  computed: {
    svgMarkup() {
      return EngagementToolkitSvgFromContext(`${this.iconName}.svg`);
    },
  },
  mounted() {
    if (!this.suppressImg) {
      this.$nextTick(this.controlAnimation);
    }
  },
  methods: {
    determineAnimationState() {
      const windowHeight = window.innerHeight;
      const topPosition = this.$refs.illustration?.getBoundingClientRect().top;
      const bottomPosition = this.$refs.illustration?.getBoundingClientRect().bottom;
      const illustrationOffScreen = bottomPosition < 0 || topPosition > windowHeight;
      const illustrationInTriggerArea = topPosition > 100 && bottomPosition < windowHeight - 50;

      if (this.animationRunning) {
        if (illustrationOffScreen) {
          this.animationRunning = false;
          /**
           * Here the animation cycle is turned off, so it can be triggered
           * to start again when the component is fully within the window.
           * The animation cycle is only turned off when the component is
           * completely out of the window to avoid the animation being suddenly
           * stopped mid-keyframe while visible.
           */
        }
        return;
      }

      if (illustrationInTriggerArea) {
        this.animationRunning = true;
      }
    },
    controlAnimation() {
      this.determineAnimationState();

      window.addEventListener('scroll', debounce(this.determineAnimationState, 100));
    },
  },
};
