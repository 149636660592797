<template>
  <div
    class="vertical-expand-text-input"
    :class="{ 'vertical-expand-text-input--over': maxCharLimitExceeded }"
  >
    <textarea
      :id="id"
      ref="input"
      v-bind="$attrs"
      class="vertical-expand-text-input__input"
      :value="value"
      @input="emitInputEvent"
      @focus="emitFocusEvent"
    />
    <div
      v-if="isFocused && value !== ''"
      class="vertical-expand-text-input__charcount"
    >{{ characterCounter(value) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerticalExpandTextInput',
  props: {
    value: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 250,
    },
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      height: '18px',
      isFocused: false,
    };
  },
  computed: {
    maxCharLimitExceeded() {
      return this.value.length > this.maxLength;
    },
  },
  methods: {
    characterCounter(input) {
      if (input.length === 0) {
        return `Maximum ${this.maxLength} characters`;
      }
      if (input.length <= this.maxLength) {
        return `${this.maxLength - input.length} characters remaining`;
      }
      return `${Math.abs(this.maxLength - input.length)} characters over limit`;
    },
    emitFocusEvent() {
      this.isFocused = true;
      this.$emit('focus');
    },
    emitInputEvent(event) {
      this.$emit('input', event.target.value);
      this.resize();
    },
    resize() {
      const element = this.$refs.input;
      element.style.height = this.height;
      element.style.height = `${(element.scrollHeight + 2).toString()}px`;
    },
  },
};
</script>

<style lang="scss"> // no scoped - so these styles can be overridden by component styles
  @import '@/stylesheets/components/_vertical-expand-text-input';
</style>
