import gql from 'graphql-tag';

export const THREAD_REPLY_FRAGMENT = gql`
fragment ThreadReplyFragment on Reply {
  body,
  id,
  insertedAt,
  isPendingModeration
  user {
    id
    username
    insertedAt
    roleId
    badge
    avatar {
      thumb
    }
  }
  inReplyTo {
    id
  }
}`;

// Query against cache when updating following CREATE_REPLY mutation
export const REPLY_UPDATE_QUERY = gql`
${THREAD_REPLY_FRAGMENT}
query thread($id: NexusId, $slug: String) {
  thread(id: $id, slug: $slug) {
    id
    replies {
      ...ThreadReplyFragment
      replies {
        ...ThreadReplyFragment
      }
    }
  }
}
`;
