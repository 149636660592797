import gql from 'graphql-tag';

export const ADD_THREAD = gql`
mutation createThread($body: Ugc!, $name: String!, $optIn: Boolean, $slug: String, $tags: [String], $engagementId: NexusId) {
  createThread(body: $body, name: $name, optIn: $optIn, slug: $slug, tags: $tags, engagementId: $engagementId) {
    body
    id
    insertedAt
    isFeatured
    isPendingModeration
    name
    replies {
      id
    }
    slug
    tags{
      id
      name
    }
    user{
      username
      insertedAt
      id
    }
  }
}
`;

export const CREATE_REPLY = gql`
mutation createReply($body: Ugc!, $inReplyToId: NexusId, $threadId: NexusId!, $engagementId: ID) {
  createReply(body: $body, inReplyToId: $inReplyToId, threadId: $threadId, engagementId: $engagementId) {
    body,
    id,
    isPendingModeration,
    insertedAt,
    inReplyTo {
      id
    }
    replies {
      id
    }
    thread {
      id
      slug
    }
    user {
      username
      insertedAt
      id
      badge
      roleId
      avatar {
        thumb
      }
    }
  }
}
`;

export const DELETE_THREAD = gql`
mutation deleteThread($id: NexusId!) {
  deleteThread(id: $id) {
    id
  }
}
`;

export const DELETE_REPLY = gql`
mutation deleteReply($id: NexusId!) {
  deleteReply(id: $id) {
    id
  }
}
`;
