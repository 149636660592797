<template>
  <router-link
    v-if="ctaLink"
    :to="{ path: `${ctaLink}`, query : { scroll : true } }"
    class="forum-driver-success link--unstyled"
  >
    <h2 class="forum-driver-success__heading">
      Your response has been submitted to the forum.
    </h2>
    <p class="forum-driver-success__cta-text">
      View all responses
      <icon-component
        name="caret"
        title="caret icon"
        class="forum-driver-success__cta-icon"
      />
    </p>
  </router-link>
</template>

<script>
export default {
  name: 'ForumDriverSuccess',
  props: {
    ctaLink: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_forum-driver-success';
</style>
