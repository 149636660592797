<template>
  <div class="forum-driver">
    <div class="forum-driver__image-container">
      <div
        ref="illustration"
        class="forum-driver__icon"
        :class="{ 'forum-driver__icon--animated': animationRunning }"
        v-html="svgMarkup"
      />
    </div>
    <forum-driver-success
      v-if="showSuccess"
      :cta-link="data.ctaLink"
    />
    <div
      v-else
      class="forum-driver__content-container"
    >
      <h2 class="forum-driver__heading">
        Featured Forum
      </h2>
      <forum-driver-form
        :slug-text="slugText"
        :question="data.question"
        :thread-id="data.threadId"
        :engagement-id="data.id"
        @submitted="changeToSuccess"
      />
      <router-link
        v-if="data.ctaLink"
        :to="{ path: `${data.ctaLink}`, query : { scroll : true } }"
        class="forum-driver__cta-link"
      >
        View all responses
        <icon-component
          name="caret"
          title="caret icon"
          class="forum-driver__cta-icon"
        />
      </router-link>
    </div>
  </div>
</template>

<script>
import ForumDriverForm from '@/components/EngagementToolkit/ForumDriverForm.vue';
import ForumDriverSuccess from '@/components/EngagementToolkit/ForumDriverSuccess.vue';
import engagementToolkitMixin from '@/mixins/engagement-toolkit-mixin';

export default {
  name: 'ForumDriver',
  components: {
    ForumDriverForm,
    ForumDriverSuccess,
  },
  mixins: [engagementToolkitMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showSuccess: false,
      iconName: 'forum-driver',
    };
  },
  computed: {
    slugText() {
      return this.data.ctaLink.replace(/^\/forums\//, '') || null;
    },
  },
  methods: {
    changeToSuccess() {
      this.showSuccess = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/stylesheets/components/_forum-driver';
</style>

<style lang="scss"> // no scoped to reach the svg elements within v-html
  // special stylesheet just for the animation
  @import '@/stylesheets/components/_forum-driver-icon';
</style>
